import * as React from "react"
import "tailwindcss/tailwind.css"
import Services from "./Services";
import {StaticImage} from "gatsby-plugin-image";

// markup
const IndexPage = () => {
  return (
    <div className="lg:relative">
      <div className="mx-auto max-w-7xl w-full pt-8 pb-20 text-center lg:py-24 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1
            className="text-2xl font-extrabold text-gray-900 sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl">
            <span className="block xl:inline">Hi,</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-md text-gray-500 sm:text-md md:mt-5 md:max-w-3xl">
            Whether you’re a parent unsure of what do to, an adolescent trying to find their place, or a child looking for a space to connect with your parent and to be a child, I am here for you!
          </p>
          <p className="mt-3 max-w-md mx-auto text-md text-gray-500 sm:text-md md:mt-5 md:max-w-3xl">
            My goal for every family or individual that walks through my doors is to ensure I am the best fit therapist for you and can provide you with a safe, comfortable environment.  My commitment and promise to you is to be informative, supportive and challenge you, when needed to ensure treatment goals are being met.
          </p>
        </div>
      </div>
      <StaticImage
        src="../images/ka.jpg"
        className="relative lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2"
        alt="Katrina"
        objectFit="cover"
      />
    </div>
  )
}

export default IndexPage
