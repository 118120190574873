import * as React from "react"
import "tailwindcss/tailwind.css"
import Hero from "../components/Hero";
import Services from "../components/Services";
import Diagnoses from "../components/Diagnoses";
import Contact from "../components/Contact";
import {GlobeAltIcon, HomeIcon, ScaleIcon} from "@heroicons/react/outline";
import {AnnotationIcon, LightningBoltIcon, MailIcon, DesktopComputerIcon} from '@heroicons/react/outline'
import {StaticImage} from "gatsby-plugin-image";

const features = [
  {
    name: '6 Years of Experience',
    icon: GlobeAltIcon,
  },
  {
    name: 'Serving the greater Austin Area (Georgetown offices)',
    icon: ScaleIcon,
  },
  {
    name: 'Modern and effective forms of treatment',
    icon: LightningBoltIcon,
  },
  {
    name: 'Full confidentiality',
    icon: LightningBoltIcon,
  },
]

const blogPosts = [
  {
    id: 1,
    title: 'Serving all of Texas',
    imageUrl:
      'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
    content: [
      'In-person appointments available to the greater Austin area (in Georgetown).',
      'Virtual appointments available to any Texan.'
    ]
  },
  {
    id: 2,
    title: 'Affordable',
    imageUrl:
      'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
    content: [
      'Hourly appointments are $175/hr.',
      'Superbills available to assist in billing insurance companies.'
    ]
  },
  {
    id: 3,
    title: 'Experienced',
    imageUrl:
      'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
    content: [
      '10 years of experience.',
      'See my modalities',
      'See symptoms I treat'
    ]
  },
]

// markup
const IndexPage = () => {
  return (
    <main className="mt-8 mx-auto max-w-7xl px-4">
      <div className="lg:relative">

        <a href="https://inspiredpsych.org" className="flex flex-row-reverse">Visit Katrina's practice</a>

        <div className="relative mt-2">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"/>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <StaticImage
                  className="h-full w-full object-cover"
                  src="../images/wheat.jpg"
                  alt="Hand in hand"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-300 to-gray-100 mix-blend-multiply"/>
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h2 className="text-center text-3xl font-extrabold tracking-tight">
                  <span className="block text-gray-50">Providing Therapy Services for Those in Need</span>
                </h2>
                <h2 className="text-center mt-4 font-extrabold tracking-tight sm:text-xl lg:text-xl">
                  <span className="block text-gray-50">Katrina Auwaerter, LPC</span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <Hero/>

        <div className="py-12 bg-primary">
          <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2 className="sr-only">A better way to send money.</h2>
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-8">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dt>
                    <div className="grid items-center text-center justify-items-center h-12 w-12 items-center mx-auto rounded-md bg-black text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true"/>
                    </div>
                    <p className="mt-5 text-lg text-center leading-6 font-medium text-gray-900">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        <Services/>
        {/*<Diagnoses/>*/}
        <Contact/>
      </div>
    </main>
  )
}

export default IndexPage
